import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ProjectsNavigation from "../../components/projects-navigation"
import ProjectUFG from "../../components/project-ufg"
import { content } from "../pages.module.scss"

export default function PageProjectUFG() {
  const currentLang = "en"
  return (
    <Layout lang={currentLang}>
      <Seo
        lang={currentLang}
        title="Project - Ubezpieczeniowy Fundusz Gwarancyjny"
        keywords={[`powszuk`, `design`]}
      />
      <div className={content}>
        <ProjectUFG lang={currentLang} />
      </div>
      <ProjectsNavigation lang={currentLang} project="ufg" />
    </Layout>
  )
}
